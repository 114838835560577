
// import folkus_logo from '../../assets/img/folkus_logo.png';
import Logo_final from '../../assets/img/fmlogo.jpg';
import { useNavigate, useLocation } from 'react-router-dom';
import React, { useState, useEffect } from 'react';


// import ls from 'local-storage';

const Header = (props) => {
    const navigate = useNavigate();
    // console.log("userData",userData);
    const [showDropdown, setShowDropdown] = useState(false);

    const location = useLocation();





    // const sideBarOpen = ls.length("headerHide");


    //const handleButtonClick = () => {

    // setShowDropdown(!showDropdown);
    // };







    const handleButtonClick = (event) => {
        event.preventDefault();
        setShowDropdown((prev) => !prev); // Toggle dropdown visibility
    };


    {/*

    const handleButtonClick1 = (event) => {
        const openPaths = ['/customers'];
        event.preventDefault();
        setShowDropdown((prev) => !prev); 
        
        // Navigate to '/customers'
        window.location.href = openPaths[0]; 
    };

    */}


   

    // Handle link click and maintain scroll position
    const handleLinkClick = (path, event) => {
        event.preventDefault();
        
        const currentScrollY = document.querySelector('.components').scrollTop;
        navigate(path);

        setTimeout(() => {
            document.querySelector('.components').scrollTop = currentScrollY;
        }, 20);
    };

   
    useEffect(() => {
       
        const openPaths = ['/Config', '/AssignWebphoneConfigList', '/CustomerCountPage', '/customers', '/Callqueue', '/CallqueueAdd'];

        
        if (openPaths.includes(location.pathname)) {
            setShowDropdown(true);
        } else {
            setShowDropdown(false);
        }
    }, [location.pathname]);






    return (
        <>
            <nav id="sidebar"
                className={!props.sideBarOpen ? '' : 'active'} >
                <div onClick={() => navigate('/dashboard')} className="sidebar-header">
                    <img src={Logo_final} className="img-fluid" />
                    <img src={Logo_final} className="img-simpl" />  
                </div>
                <ul className="list-unstyled components">
                    <li className={location.pathname === '/dashboard' ? 'active' : ''} >
                        <a onClick={() => navigate('/dashboard')} className="dashboard"><i className="fa-solid fa-gauge"></i><span>Dashboard</span></a>
                    </li>
                    <li className={location.pathname === '/admin' ? 'active' : ''} >
                        <a onClick={() => navigate('/admin')}><i className="fa-solid fa-user-gear"></i><span>Admin</span></a>
                    </li>


                      {/*

                    <li className={location.pathname === '/customers' ? 'active' : ''} >
                        <a onClick={() => navigate('/customers')}><i className="fa-solid fa-users"></i><span>Customers</span></a>
                    </li>


                    <li className= {location.pathname === '/Callqueue' ? 'active' :''}>
                      <a onClick = {()=> navigate('/Callqueue')}><i className="fa-solid fa-user-group"></i><span>Assign Customers</span></a>
                       </li>


                    <li className= {location.pathname === '/CallqueueAdd' ? 'active':''}>
                    <a onClick = {()=> navigate('/CallqueueAdd')}><i className="fa-solid fa-user-group"></i>Not Assign Customers</a>
                    </li>
                       */}


<li>
    <button
        id="Settings_outer"
        className="sidebar-link btn btn-toggle align-items-center"
        onClick={handleButtonClick}
        aria-expanded={showDropdown}
    >
        <i className="fa-solid fa-users"></i>
        <span className="hide-menu">Customers</span>
        <i className={`fas fa-chevron-right ms-auto ${showDropdown ? 'rotate' : ''}`}></i>
    </button>

    <div className={`collapse ${showDropdown ? 'show' : ''}`} id="Settings-collapse">
        <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
            {/* List item for 'Customers' */}
            <li className={location.pathname === '/customers' ? 'active' : ''}>
                <a onClick={(event) => handleLinkClick('/customers', event)} className="Config">
                <i className="fas fa-circle inner-circle small-font" style={{ fontSize: '8px' }}></i>< span >Customers</span>
                </a>
            </li>

            {/* List item for 'Assign Customers' */}
            <li className={location.pathname === '/Callqueue' ? 'active' : ''}>
                <a onClick={(event) => handleLinkClick('/Callqueue', event)} className="CustomerCountPage">
                <i className="fas fa-circle inner-circle"  style={{ fontSize: '8px' }}></i><span>Assigned Customers</span>
                </a>
            </li>

            {/* List item for 'Not Assign Customers' */}
            <li className={location.pathname === '/CallqueueAdd' ? 'active' : ''}>
                <a onClick={(event) => handleLinkClick('/CallqueueAdd', event)} className="CustomerCountPage">
                <i className="fas fa-circle inner-circle"  style={{ fontSize: '8px' }}></i><span>Not Assigned Customers</span>
                </a>
            </li>
        </ul>
    </div>
</li>








                    <li className={location.pathname === '/operator' ? 'active' : ''} >
                        <a onClick={() => navigate('/operator')}><i className="fa-brands fa-ubuntu"></i><span>Operator</span></a>
                    </li>
                    <li className={location.pathname === '/CompanyList' ? 'active' : ''} >
                        <a onClick={() => navigate('/CompanyList')}><i className="fa-sharp fa-solid fa-building"></i><span>Company</span></a>
                    </li>
                    {/* <li className={location.pathname === '/calls' ? 'active' : ''} >
                        <a onClick={() => navigate('/calls')}><i className="fa-solid fa-phone"></i><span>Calls</span></a>
                    </li> */}
                    <li className={location.pathname === '/Reports' ? 'active' : ''} >
                        <a onClick={() => navigate('/Reports')}><i className="fa-solid fa-bug"></i><span>Operator Report</span></a>
                    </li>
                    {/* <li className={location.pathname === '/customersMap' ? 'active' : ''} >
                        <a onClick={() => navigate('/customersMap')}><i className="fa-solid fa-map-location-dot"></i><span>Customers Map</span></a>
                    </li> */}
                    <li className={location.pathname === '/history' ? 'active' : ''} >
                        <a onClick={() => navigate('/history')}><i className="fa-solid fa-users"></i><span>History</span></a>
                    </li>
                    <li className={location.pathname === '/OperatorActivity' ? 'active' : ''} >
                        <a onClick={() => navigate('/OperatorActivity')}><i className="fa-solid fa-bug"></i><span>Operator Activity</span></a>
                    </li>
                    {/* <li className={location.pathname === '/AssignCustomer' ? 'active' : ''} >
                        <a onClick={() => navigate('/AssignCustomer')}><i className="fa-solid fa-users"></i><span>Assign Customer</span></a>
                    </li> */}
                    {/* <li className={location.pathname === '/Reschedule' ? 'active' : ''} >
                        <a onClick={() => navigate('/Reschedule')}><i className="fa-regular fa-calendar-check"></i><span>Reschedule</span></a>
                    </li> */}


                    {/*
                    <li className={location.pathname === '/Callqueue' ? 'active' : ''} >
                        <a onClick={() => navigate('/Callqueue')}><i className="fa-solid fa-user-group"></i><span>Call Queue</span></a>
                    </li>

                     */}


                    <li className={location.pathname === '/ListName' ? 'active' : ''} >
                        <a onClick={() => navigate('/ListName')}><i className="fa-solid fa-list"></i><span>List Name</span></a>
                    </li>
                    <li className={location.pathname === '/AssignList' ? 'active' : ''} >
                        <a onClick={() => navigate('/AssignList')}><i className="fa-solid fa-list-check"></i><span>Assign List</span></a>
                    </li>

                    <li>
                        <button
                            id="Settings_outer"
                            className="sidebar-link btn btn-toggle align-items-center"
                            onClick={handleButtonClick}
                            aria-expanded={showDropdown}
                        >
                            <i className="fa-solid fa-gear"></i>
                            <span className="hide-menu">Settings</span>
                        </button>

                        <div className={`collapse ${showDropdown ? 'show' : ''}`} id="Settings-collapse">
                            <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small">
                                <li className={location.pathname === '/Config' ? 'active' : ''}>
                                    <a onClick={(event) => handleLinkClick('/Config', event)} className="Config">
                                        
                                        <span>Webphone Config</span>
                                    </a>
                                </li>
                                <li className={location.pathname === '/AssignWebphoneConfigList' ? 'active' : ''}>
                                    <a onClick={(event) => handleLinkClick('/AssignWebphoneConfigList', event)} className="CustomerCountPage">
                                       
                                        <span>Assign Webphone Config List</span>
                                    </a>
                                </li>
                                <li className={location.pathname === '/CustomerCountPage' ? 'active' : ''}>
                                    <a onClick={(event) => handleLinkClick('/CustomerCountPage', event)} className="CustomerCountPage">
                                       
                                        <span>Assign Customer Count Page</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </li>
                    {/* <li >
                        <div className="iocn-link">                             
                            <a onClick={() => navigate('/Config')}><i className="fa-solid fa-gear"></i><span>Setting</span>  <i className="fa-solid fa-chevron-down"></i></a>

                        </div>
                       
                    </li> */}
                    {/* <li>
                        <div className="sidebar">
                            <button onClick={toggleDropdown}>Setting</button>

                            {showDropdown && (
                                <div className="dropdown123">
                                    <ul className="sub-menu">
                                        <li className={location.pathname === '/Config' ? 'active' : ''} >
                                            <a onClick={() => navigate('/Config')} className="Config"><i className="fa-solid fa-gauge"></i><span>Webphone Config</span></a>
                                        </li>
                                        <li className={location.pathname === '/CustomerCountPage' ? 'active' : ''} >
                                            <a onClick={() => navigate('/CustomerCountPage')} className="CustomerCountPage"><i className="fa-solid fa-user-gear"></i><span>AssignCustomer CountPage</span></a>
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </li> */}
                </ul>
            </nav>
        </>
    );
};
export default Header;