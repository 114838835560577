import React, { useState, useEffect } from 'react';
import Header from '../Header/header';
import SideBar from '../SideBar/sidebar';
import { useNavigate } from 'react-router-dom';
import API from '../../Services/BaseService';
import Loading from '../../Component/Loading/Loading';
import Pagination from 'reactjs-hooks-pagination';
//import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import ls from 'local-storage';
import moment from 'moment';
// import { data } from 'jquery';



const Callqueue = () => {
    const [adminInfo, setAdminInfo] = useState("");
    const [loading, setLoading] = useState(true)
    const [totalRecords, setTotalRecords] = useState(0);
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState("");
    const [CustomerName, setCustomerName] = useState("");
    //const [listname,setListName] = useState("");
    // const [firstlist,setFirstList] =useState("");
    const [/* operatorName */, setOperatorName] = useState("");
    const [/* userName */, setUserName] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    // const [fromdate, setfromdate] = useState("");
    // const [todate, settodate] = useState("");
    //const [fromDateError, setFromDateError] = useState("");
    const [PageNn, setPageNo] = useState(10);
    const [operatorID, setoperatorID] = useState("");
    const [/* operator */, setoperator] = useState("");
    const [/*list */ ,setList] = useState("");
    const [selectlist,setSelectList] =useState("");
    const [Selectedoperator, setSelectedoperator] = useState("");
    const [Count, setCount] = useState("");


    const pageLimit = PageNn;

    const navigate = useNavigate();
    const [isOpen, setIsOpen] = useState(false);
    const recallfunction = (data) => {
        // console.log('reCalling==', data);
        setIsOpen(data);
    };

    useEffect(() => {
        AdminList();
    }, [currentPage, pageLimit])

    // ============================List===============================
    const AdminList = () => {
        let request ={
            customer_name: CustomerName,
            operator_id:  !Selectedoperator ? "" : Selectedoperator.operator_id,
            customer_list_id: !selectlist ? "" : selectlist.customer_list_id,
            operator_name: "",
            Phone: phoneNo,
            data: search,
            rowsPerPage: pageLimit,
            pageNumber: currentPage
        }
        
        API.post('operatorcallqueuesearch/condition',request).then((response) => {
            if (response.data.success == true) {
                setAdminInfo(response.data.data);
                setCount(response.data.data1[0].count);
                setTotalRecords(response.data.data1[0].count);
               /*  document.getElementById("page").value = "10";
                setCurrentPage(1); */
                setLoading(false);
               /*  setPageNo(10); */
            }
        });
    }


    const AdminListClear = () => {
        let request ={
            customer_name: "",
            operator_id:  "" ,
            customer_list_id: "",
            operator_name: "",
            Phone: "",
            data: "",
            rowsPerPage: "10",
            pageNumber: "1"
        }
        
        API.post('operatorcallqueuesearch/condition',request).then((response) => {
            if (response.data.success == true) {
                setAdminInfo(response.data.data);
                setCount(response.data.data1[0].count);
                setTotalRecords(response.data.data1[0].count);
               /*  document.getElementById("page").value = "10";
                setCurrentPage(1); */
                setLoading(false);
               /*  setPageNo(10); */
            }
        });

    }

    useEffect(() => {
        OperatorList();
    }, [])

    const OperatorList = () => {
        API.post('operatorlist/condition',).then((response) => {
            if (response.data.success == true) {

                setoperatorID(response.data.data);

            }
        });
    }

    // =========================Search============================

    const onSearch = (e) => {
        setSearch(e.target.value);
    }

    const adminSearch = () => {
        let request = {
            data: search,
             rowsPerPage: pageLimit,
            pageNumber: currentPage
        }

        API.post("operatorcallqueuelistsearch/condition", request).then(response => {
            setAdminInfo(response.data.data);
            setCount(response.data.data1[0].count);
            setTotalRecords(response.data.data1[0].count);
            /* setCurrentPage(1); */
        });
    }

//const [operatorlist,setOperatorList] = useState("");

   



    useEffect(() => {
        if (search != "") {
            const timer = setTimeout(() => {
                adminSearch();
            }, 200);
            return () => {
                clearTimeout(timer);
            };
        } else {
            setLoading(true);
            AdminList();
        }
    }, [search]);

    const onKeydownAccountSearch = (event) => {
        if (event.key === "Enter") {
            adminSearch();
        }
    };
    // const disablePastDt = current => {
    //     return current.isAfter(fromdate);
    // };

    // const inputProps = {
    //     placeholder: "From Date",
    //     value: fromdate
    // };
    // const inputProps1 = {
    //     placeholder: "To Date",
    //     value: todate
    // };

    // const fromDate = (event) => {
    //     if (event._isAMomentObject == true) {
    //         setfromdate(event.format("YYYY-MM-DD"))
    //         settodate("");
    //     }
    // }
    // const toDate = (event) => {
    //     if (event._isAMomentObject == true) {
    //         settodate(event.format("YYYY-MM-DD"))
    //     }
    // }
    const handleBuyerId = (e) => {
        setoperator(e.target.value);

        console.log("=========check", e.target.value);
        setSelectedoperator(
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
    
        );
        console.log(
            "=========check123",
            operatorID.filter((data) => data.operator_id == e.target.value)[0]
        );
    };

    const handleListId = (e) => {
        setList(e.target.value);
        setSelectList(
            custlist.filter((data) => data.customer_list_id == e.target.value)[0]
        );
    }

    
    
const [custlist,setCustList] = useState("");

    const Listservice = ()=>{
        API.post('listService/condition',).then((response) => {
            if (response.data.success == true) {

                setCustList(response.data.data);

            }
        });


    }
    useEffect(()=>{
        Listservice();

    },[])



    const callqueueFilter = () => {
        // setFromDateError("")

        // if (fromdate) {
        //     if (!todate) {
        //         setFromDateError("To Date is required")
        //         return;
        //     }

        // }
        setLoading(true)
        let request = {
            customer_name: CustomerName,
            operator_id:  !Selectedoperator ? "" : Selectedoperator.operator_id,
            customer_list_id: !selectlist ? "" : selectlist.customer_list_id,
            operator_name: "",
            Phone: phoneNo,
            data: search,
            rowsPerPage: pageLimit,
            pageNumber: currentPage
        }

        API.post("operatorcallqueuesearch/condition", request).then(response => {
            setAdminInfo(response.data.data);
            setCount(response.data.data1[0].count);
            setTotalRecords(response.data.data1[0].count);
            setLoading(false)
            /* document.getElementById("page").value = "10";
            setCurrentPage(1);
            setPageNo(10); */
        });
    }

    const handleSearch = () => {
        callqueueFilter();
    }

    const handleclear = () => {
        setOperatorName("");
        //setListName("");
        setCustomerName("");
        
        setUserName("");
        setPhoneNo("");
        setoperatorID("");
        setCustList("");
        setSelectList("");
        // setfromdate("");
        //settodate("");
        //setPageNo("");
        setSelectedoperator();
        setLoading(true);
        OperatorList();
        Listservice();
        AdminListClear();
        
        // setFromDateError();
       // document.getElementById("page").value = "10";
    }

    function formatMobileNO(value) {
        var x = value.replace(/\D/g, '').match(/(\d{3})(\d{3})(\d{4})/);
        // console.log("value of x", x);
        value = '+1 ' + '(' + x[1] + ') ' + x[2] + '-' + x[3];
        //console.log("mobileno", value);
        return value;
    }
    const redirection = (id) => {

        navigate({ pathname: "/CustomerView" });

        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    const redirectionoperator = (id) => {

        navigate({ pathname: "/OperatorView" });
        ls.set("AdminId", id);
        console.log("AdminId", id);
    };

    //asperpage

    //sorting

  const [sorted, setSorted] = useState({ sorted: "id", reversed: false })

    const sortById = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
          if (sorted.reversed) {
            console.log("sortById if");
            return userA.callqueueid - userB.callqueueid;
          }
          return userB.callqueueid - userA.callqueueid;
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "id", reversed: !sorted.reversed });
      };

      const sortByCustomerName = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            userA = userA.customername || '';
            userB = userB.customername || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }
            return userA.localeCompare(userB);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "customername", reversed: !sorted.reversed });
    };

    const sortByPhone = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
          if (sorted.reversed) {
            console.log("sortById if");
            return userA.Phone - userB.Phone;
          }
          return userB.Phone - userA.Phone;
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "Phone", reversed: !sorted.reversed });
      };

      const sortByOperatorName = () => {
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            userA = userA.operatorname || '';
            userB = userB.operatorname || '';
            if (sorted.reversed) {
                return userB.localeCompare(userA);
            }
            return userA.localeCompare(userB);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "operatorname", reversed: !sorted.reversed });
    };
    const sortByListName = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];

        usersCopy.sort((userA, userB) => {
            userA = userA.list_name || '';
            userB = userB.list_name || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }

            return userA.localeCompare(userB);

        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "list_name", reversed: !sorted.reversed });

    };

    const sortByDatetime = () => {
        console.log("sortById start");
        const usersCopy = [...adminInfo];
        usersCopy.sort((userA, userB) => {
            userA = userA.assigndatetime || '';
            userB = userB.assigndatetime || '';
            if (sorted.reversed) {
                console.log("sortById if");
                return userB.localeCompare(userA);
            }
            return userA.localeCompare(userB);
        });
        setAdminInfo(usersCopy);
        setSorted({ sorted: "assigndatetime", reversed: !sorted.reversed });
    };

   function formatTime(value) {
        let local = ""
       // let offset = ""
        // let utc1 = ""
       // console.log(value, "yyyy1");
       value = value.replace(" ","T")
       value = value+"Z";
       console.log(value, "yyyy1");
        local = new Date(value);
       value =local.toLocaleString();
        //var local = new Date("2023-05-22 08:21:11");
        // offset = local.getTimezoneOffset();
        // value = new Date(local.getTime() - offset * 60000);
        // console.log(value, "yyy");
         value = moment(value).format('MMMM D, YYYY hh:mm A')
        // let utc2222 = utc1
        console.log(value, "yyyy");
        return value;
    }


    



    return (
        <div className="wrapper">
            <SideBar sideBarOpen={isOpen} />
            <div id="content" className={!isOpen ? '' : 'active'}>
                <Header recallfunction={recallfunction} />
                <div className="main-content">

                  {/* <h5 className="heading-text">Call Queue</h5> */}

                  <h5 className="heading-text">Assign Customers</h5> 

                    {/*

                    <div className="row">
                    <div className="col">


        
                   <p className="add-btns"  onClick={() => navigate("")}>Assigned Customer</p>
                   <p className="add-btns1" onClick={() => navigate("/CallqueueAdd")}>Not Assigned Customer</p>

    
            
                        </div>
                        </div>

                    */}

                    <div className="row">
                        <div className="col-xl-8">
                            <div className="common-heading-sec">
                                {/* <div className="search-bar">
                                    <input type="text" className="form-control" value={operatorName} placeholder="Operator Name" onChange={(e) => setOperatorName(e.target.value)} />
                                </div> */}
                                <div className=" col-md-4 col-lg-3 search-bar">
                                    {/* <label className="form-label">
                                        Operator
                                    </label> */}
                                    <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        id="Sale"
                                        onChange={handleBuyerId}
                                    >
                                        <option style={{ display: "none" }}>Operator Name</option>
                                        {operatorID?.length > 0 && (
                                            <>
                                                {operatorID.map((operatorID) => (
                                                    <option
                                                        key={operatorID.operator_id}
                                                        value={operatorID.operator_id}
                                                    >
                                                        {operatorID.operatorname}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                                <div className="col-md-4 col-lg-2 search-bar">
                                    <select className='form-select' aria-label="Default select example" id='Sale' onChange={handleListId} >
                                   <option style={{display : "none"}}>List Name</option>
                                   {custlist?.length > 0 && (
                                            <>
                                                {custlist.map((custlist) => (
                                                    <option
                                                        key={custlist.customer_list_id}
                                                        value={custlist.customer_list_id}
                                                    >
                                                        {custlist.list_name}
                                                    </option>
                                                ))}
                                            </>
                                        )}
                                    </select>
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={CustomerName} placeholder="Customer Name" onChange={(e) => setCustomerName(e.target.value)} />
                                </div>
                                <div className="search-bar">
                                    <input type="text" className="form-control" value={phoneNo} placeholder=" Phone#" maxLength={10} onChange={(e) => setPhoneNo(e.target.value.replace(/\D/g, ""))} />
                                </div>

                                {/* <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" onChange={fromDate} closeOnSelect={true}
                                        id="meeting_date" />
                                </div> */}


                                {/* <div className="datePickerBlock">
                                    <Datetime inputProps={inputProps1} timeFormat={false} dateFormat="YYYY-MM-DD"
                                        name="Date" isValidDate={disablePastDt} onChange={toDate} closeOnSelect={true}
                                        id="meeting_date" />
                                    <div className="error-msgs">
                                        <p className="form-input-error" >{fromDateError}</p>
                                    </div>
                                </div> */}



                                <div className="search-clear" onClick={handleSearch}><i className="fa-solid fa-magnifying-glass"></i>Find</div>
                                <div className="search-clear" onClick={handleclear}><i className="fa-solid fa-ban"></i>Clear</div>
                                </div>
                                </div>
                         <div className='col-xl-4'>
                        <div className='add-section'>
                                <div className="search-bar">
                                    <input type="search" className="form-control" value={search} placeholder="Name or Phone# " onKeyDown={onKeydownAccountSearch} onChange={onSearch} />
                                </div>

                                {/* <div className="add-btn" onClick={() => navigate("/CallqueueAdd")}><i className="fa-solid fa-plus"></i>Assign Customer</div> */}
                            </div>
                            </div>
                            
                        

                        
                    
                    



                    <div className="common-heading-sec col-lg-12 pageno">
                        <span className="row-per-page">Rows per page:
                            <span>
                                <div>
                                    <select name="page" id="page" onChange={(e) => setPageNo(e.target.value)}>
                                    <option value="10">10</option>
                                        <option value="50">50</option>
                                        <option value="100">100</option>
                                        <option value="200">200</option>
                                        <option value="500">500</option>
                                    </select>
                                </div>
                            </span>
                            <div className="count">
                            <span> Count :{Count}</span>
                        </div>
                        </span>
                        </div>
                        </div>

                    {loading ? <Loading /> :
                        <>
                            <div className="rounded table-responsive">

                                <table className="table bg-white">
                                    <thead>
                                        <tr>
                                            <th onClick={sortById}>#<i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByCustomerName}>Customer Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByPhone}>Phone# <i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByOperatorName}>Operator Name <i className="fa fa-sort" aria-hidden="true"></i></th>
                                            <th onClick={sortByListName}>List Name <i className="fa fa-sort" aria-hidden="true"></i> </th>
                                            <th onClick={sortByDatetime}>Assign Date & Time <i className="fa fa-sort" aria-hidden="true"></i></th>

                                            <th className='text-center action-right-fix-list'>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {adminInfo?.length > 0 ? (adminInfo.sort((a, b) => b.adminInfo - a.adminInfo)
                                            .map((adminInfo, index) =>(
                                                <tr key={index}>
                                                    <td>{adminInfo.callqueueid}</td>
                                                    <td><a onClick={() => redirection(adminInfo.customer_id)}>{adminInfo.customername}</a></td>
                                                    <td>{formatMobileNO(adminInfo.Phone)}</td>
                                                    <td><a onClick={() => redirectionoperator(adminInfo.operator_id)}>{adminInfo.operatorname}</a></td>
                                                    <td>{adminInfo.list_name}</td>
                                                    <td>{formatTime(adminInfo.assigndatetimes)}</td>
                                                    {/* <td>{adminInfo.assigndatetime}</td> */}
                                                    <td className='text-center action-right-fix-list'><a className="edit me-2" onClick={() => navigate('/CallqueueEdit', { state: { call_queue_id: adminInfo.call_queue_id } })}>Edit</a>
                                                        <a className="edit" onClick={() => navigate('/CallqueueView', { state: { call_queue_id: adminInfo.call_queue_id } })}>View</a></td>


                                                </tr>
                                            ))) : <tr><td colSpan="20" className='text-center'><p className="nodata-found">No Data Found</p> </td></tr>}
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination">
                                <Pagination className=""
                                    totalRecords={totalRecords}
                                    pageLimit={pageLimit}
                                    pageRangeDisplayed={1}
                                    onChangePage={setCurrentPage}/>
                            </ul>
                        </>}


          </div>
          </div>
          </div>  

    
    );
};
export default Callqueue;